<script setup>
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onBeforeMount, onMounted } from "vue";
import AOS from "aos";
// import { ref } from "vue";
// import { useElementVisibility } from "@vueuse/core";
// const sidebarTop = ref(null);
// const scrolledToSidebarTop = useElementVisibility(sidebarTop);

// const valueName = ref("one");

import { useHead } from "@vueuse/head";

const siteData = {
  title: `About Pen - PEN UNDERWRITING`,
  url: `https://www.penunderwriting.com.au/#/about-pen`,
  description: `Learn about Pen Underwriting dynamic customer-centric agency that provides outstanding experiences to brokers. We strive to deliver consistent quality service.`,
};

onBeforeMount(() => {
  useHead({
    // Update Title and Meta
    title: siteData.title,
    meta: [
      { name: `description`, content: siteData.description },
      { name: `twitter:description`, content: siteData.description },
      { name: `twitter:title`, content: siteData.title },
      { name: `twitter:url`, content: siteData.url },
      { property: `og:site_name`, content: siteData.title },
      { property: `og:title`, content: siteData.title },
      { property: `og:url`, content: siteData.url },
      { property: `og:type`, content: siteData.type },
      {
        property: `og:description`,
        content: siteData.description,
      },
      { itemprop: `name`, content: siteData.title },
      { itemprop: `url`, content: siteData.url },
      {
        itemprop: `description`,
        content: siteData.description,
      },
    ],
  });
});

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <div>
    <HeaderSection />

    <main id="main">
      <section id="about-us" class="section about">
        <div
          class="side-triangle"
          data-aos="zoom-in"
          data-aos-delay="200"
        ></div>
        <header class="header-banner common about d-flex justify-content-start">
          <h2 class="highlight">About Pen Underwriting</h2>
        </header>
        <div class="container" data-aos="zoom-in">
          <div class="row">
            <div class="col">
              <section id="">
                <div id="about-anchor" class="page-anchor"></div>
                <h2 class="highlight">
                  Pen Underwriting: A Decade of History, a Millennium of
                  Experience
                </h2>
                <p class="pt-5">
                  Over the last decade Pen Underwriting has established itself
                  as a leading insurance underwriting agency. Our talented team
                  of underwriters boasts a collective experience of over 1,000
                  years, and we leverage this deep expertise every day to assist
                  our broker partners find solutions for their clients. From our
                  offices in Sydney, Brisbane, and Melbourne, we specialise in
                  placing cover for complex risks across a broad range of
                  products and industries.
                </p>
                <p class="py-1">
                  If you're seeking a reliable and experienced underwriting
                  partner, whether as a broker, an insurer, or someone
                  interested in joining our team, we invite you to explore Pen
                  Underwriting.
                </p>

                <p class="py-1">
                  Pen’s unwavering commitment to service excellence and a focus
                  on lasting partnerships have solidified our reputation as a
                  trusted underwriting agency. Our three key stakeholders are at
                  the centre of everything we do.
                </p>

                <div class="row my-4 py-4">
                  <div class="col col-sm-12 col-lg-4 card-deck mb-3">
                    <div class="card h-100">
                      <div class="card-body">
                        <h3 class="highlight">Our Teams</h3>
                        <p class="pt-3">
                          We strive to create a vibrant, safe, and inclusive
                          work environment where every team member has a voice.
                          We foster a culture of respect, collaboration,
                          innovation and professional growth.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col col-sm-12 col-lg-4 card-deck mb-3">
                    <div class="card h-100">
                      <div class="card-body">
                        <h3 class="highlight">Our Brokers</h3>
                        <p class="pt-3">
                          We are dedicated to providing exceptional service,
                          expert advice, and finding answers for even the most
                          challenging risks. Our goal is to be a trusted partner
                          to our brokers, helping them meet their clients'
                          unique needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="ccol col-sm-12 col-lg-4 card-deck mb-3">
                    <div class="card">
                      <div class="card-body">
                        <h3 class="highlight">Our Security Partners</h3>
                        <p class="pt-3">
                          We operate as true partners with our capacity
                          providers, representing their interests and aiming to
                          deliver superior underwriting results. Our expertise
                          and diligence ensure that we uphold the highest
                          standards of underwriting and portfolio management.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id="">
                <div id="leadership-anchor" class="page-anchor"></div>
                <h2 class="highlight pb-4">Leadership Team</h2>
                <div class="underwriters-wrapper row justify-content-left mt-4">
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Ken-Keenan.jpg"
                      alt="Ken Keenan - Chief Executive - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">Ken Keenan</h5>
                      <p class="division mb-2">Chief Executive</p>
                      <p class="m-0">
                        <a href="tel:0466469849">0466 469 849</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:ken_keenan@penunderwriting.com"
                          >Email</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Alisa-Martins.jpg"
                      alt="Alisa Martins - Chief Operating Officer - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">
                        Alisa Martins
                      </h5>
                      <p class="division mb-2">Chief Operating Officer</p>
                      <p class="m-0">
                        <a href="tel:0409458020">0409 458 020</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:Alisa_Martins@penunderwriting.com"
                          >Email</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Lenu-Lukose.jpg"
                      alt="Lenu Lukose - Chief Underwriting Officer - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">
                        Lenu Lukose
                      </h5>
                      <p class="division mb-2">Chief Underwriting Officer</p>
                      <p class="m-0">
                        <a href="tel:0401200925">0401 200 925</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:lenu_lukose@penunderwriting.com"
                          >Email</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Jean-Leighton.jpg"
                      alt="Jean Leighton - Chief Financial Officer - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">
                        Jean Leighton
                      </h5>
                      <p class="division mb-2">Chief Financial Officer</p>
                      <p class="m-0">
                        <a href="tel:0418757275">0418 757 275</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:Jean_Leighton@penunderwriting.com"
                          >Email</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Hun-Kim.jpg"
                      alt="Hun Kim - Chief Actuary - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">Hun Kim</h5>
                      <p class="division mb-2">Chief Actuary</p>
                      <p class="m-0">
                        <a href="tel:0478259602">0478 259 602</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:hun_kim@penunderwriting.com">Email</a>
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Nick-Best.jpg"
                      alt="Nick Best - Head of Distribution - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">Nick Best</h5>
                      <p class="division mb-2">Head of Distribution</p>
                      <p class="m-0">
                        <a href="tel:0479174406">0479 174 406</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:nick_best@penunderwriting.com">Email</a>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="underwriters-wrapper row justify-content-left mt-4">
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Paul-Behm.jpg"
                      alt="Paul Behm - Liability Team Manager - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">Paul Behm</h5>
                      <p class="division mb-2">Liability Team Manager</p>
                      <p class="m-0">
                        <a href="tel:0414950122">0414 950 122</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:paul_behm@penunderwriting.com">Email</a>
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Mary-Anne-Turner.jpg"
                      alt="Mary-Anne Turner - Property Team Manager - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">
                        Mary-Anne Turner
                      </h5>
                      <p class="division mb-2">Property Team Manager</p>
                      <p class="m-0">
                        <a href="tel:0481477076">0481 477 076</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:MaryAnne_Turner@penunderwriting.com"
                          >Email</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Dean-West.jpg"
                      alt="Dean West - National Construction & Engineering Manager - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">Dean West</h5>
                      <p class="division mb-2">
                        National Construction & Engineering Manager
                      </p>
                      <p class="m-0">
                        <a href="tel:0468542032">0468 542 032</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:dean_west@penunderwriting.com">Email</a>
                      </p>
                    </div>
                  </div>

                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Louise-Soutter.jpg"
                      alt="Louise Soutter - Financial Lines Underwriting Manager - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">
                        Louise Soutter
                      </h5>
                      <p class="division mb-2">
                        Financial Lines Underwriting Manager
                      </p>
                      <p class="m-0">
                        <a href="tel:0481058703">0481 058 703</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:louise_soutter@penunderwriting.com"
                          >Email</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Tim-Moore.jpg"
                      alt="Tim Moore - Transport Team Manager - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">Tim Moore</h5>
                      <p class="division mb-2">Transport Team Manager</p>
                      <p class="m-0">
                        <a href="tel:0417565636">0417 565 636</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:tim_moore@penunderwriting.com">Email</a>
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Sarah-Webster.jpg"
                      alt="Sarah Webster - Care Liability Team Manager - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">
                        Sarah Webster
                      </h5>
                      <p class="division mb-2">Care Liability Team Manager</p>
                      <p class="m-0">
                        <a href="tel:0423778168">0423 778 168</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:sarah_webster@penunderwriting.com"
                          >Email</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Harry-Zhang.jpg"
                      alt="Harry Zheng - Senior Risk & Compliance Manager - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">
                        Harry Zheng
                      </h5>
                      <p class="division mb-2">
                        Senior Risk & Compliance Manager
                      </p>
                      <p class="m-0">
                        <a href="tel:0466535262">0466 535 262</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:harry_zheng@penunderwriting.com"
                          >Email</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Ed-Rooke.jpg"
                      alt="Ed Rooke - Technical Manager - Liability"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">Ed Rooke</h5>
                      <p class="division mb-2">Technical Manager – Liability</p>
                      <p class="m-0">
                        <a href="tel:0403178668">0403 178 668</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:ed_rooke@penunderwriting.com">Email</a>
                      </p>
                    </div>
                  </div>
                  <div class="card underwriters col-5 col-lg-2 mr-1">
                    <img
                      class="card-img-top"
                      src="@/assets/img/pen/Adrian-Lyons.jpg"
                      alt="Adrian Lyons - Property Portfolio Manager - Pen Underwriting"
                    />
                    <div class="card-body">
                      <h5 class="card-title name highlight mb-2">
                        Adrian Lyons
                      </h5>
                      <p class="division mb-2">Property Portfolio Manager</p>
                      <p class="m-0">
                        <a href="tel:0481248181">0481 248 181</a>
                      </p>
                      <p class="m-0">
                        <a href="mailto:Adrian_Lyons@penunderwriting.com"
                          >Email</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id="">
                <div id="values-anchor" class="page-anchor"></div>
                <h1 class="highlight">Our Values</h1>
                <div class="row value-column">
                  <div class="col-6 col-lg-4">
                    <img
                      class="card-img-top icon"
                      src="@/assets/img/pen/integrity-icon.svg"
                      alt="At Pen Underwriting, we uphold the highest standards of integrity & respect"
                    />
                    <h3>Integrity & Respect</h3>
                    <p>
                      We uphold the highest standards of integrity & honesty,
                      ensuring transparency and trust with each other, our
                      brokers, and our partners. We celebrate diversity, our
                      highest priority is our inclusive workplace where our team
                      feel valued and empowered.
                    </p>
                  </div>
                  <div class="col-6 col-lg-4">
                    <img
                      class="card-img-top icon"
                      src="@/assets/img/pen/collaboration-icon.svg"
                      alt="At Pen Underwriting, we believe in building strong, lasting connection & collaborative partnerships"
                    />
                    <h3>Connection & Collaboration</h3>
                    <p>
                      We believe in building strong, lasting connections.
                      Teamwork is essential to our success. We foster
                      collaborative partnerships, working together to drive
                      success and create shared value.
                    </p>
                  </div>
                  <div class="col-6 col-lg-4">
                    <img
                      class="card-img-top icon"
                      src="@/assets/img/pen/innovation-icon.svg"
                      alt="At Pen Underwriting, we encourage a culture of innovation, curiosity and continuous
                      improvement."
                    />
                    <h3>Innovation & Agility</h3>
                    <p>
                      We encourage a culture of curiosity and continuous
                      improvement. Our team can adapt quickly to evolving needs
                      and market dynamics, ensuring we remain responsive and
                      competitive.
                    </p>
                  </div>
                  <div class="col-6 col-lg-4">
                    <img
                      class="card-img-top icon"
                      src="@/assets/img/pen/customer-icon.svg"
                      alt="We are customer focused, our brokers are at the heart of everything we do."
                    />
                    <h3>Customer Focus</h3>
                    <p>
                      Our brokers are at the heart of everything we do. We
                      strive to understand their unique needs and exceed their
                      expectations through personalised service and tailored
                      solutions. We are dedicated to delivering an exceptional
                      customer experience.
                    </p>
                  </div>
                  <div class="col-6 col-lg-4">
                    <img
                      class="card-img-top icon"
                      src="@/assets/img/pen/expertise-icon.svg"
                      alt="At Pen Underwriting, we are committed to nurturing and retaining a team that is
                      exceptionally skilled and knowledgeable."
                    />
                    <h3>Expertise</h3>
                    <p>
                      We are committed to nurturing and retaining a team that is
                      exceptionally skilled and knowledgeable. Our team's
                      expertise ensures we deliver exceptional products and
                      services, providing value to our brokers and partners.
                    </p>
                  </div>
                </div>
              </section>

              <section>
                <div id="giving-anchor" class="page-anchor"></div>
                <h1 class="highlight">Giving Back</h1>
                <p>
                  Pen Underwriting is proud to sponsor Dignity, Vinnies CEO
                  Sleepout, and 3rd Space. We are committed to supporting the
                  impactful initiatives and community outreach programs, and we
                  are honored to stand behind these organisations and help raise
                  awareness of their remarkable work.
                </p>
                <div class="row giving-back-column">
                  <div class="col-6 col-lg-4">
                    <img
                      class="card-img-top icon"
                      src="@/assets/img/pen/dignity.png"
                      alt="Supporting Dignity, a collaborative effort to end homelessness
                      in Australia"
                    />
                    <h3>Dignity</h3>
                    <p>
                      Dignity is a registered national charity established in
                      2015 bringing innovation and strength to the sector. At
                      Dignity, they believe in collaboration to end homelessness
                      in Australia. They empower people who are at risk of or
                      experiencing homelessness to prevent, respond to and end
                      their homelessness. Dignity currently provides supported
                      temporary accommodation and longer-term stable housing for
                      more than 250 people every night of the year and operates
                      multiple programs to prevent homelessness including food
                      relief and education across Australia. Since Dignity’s
                      inception, more than 65,000 people have been supported.
                    </p>
                  </div>
                  <div class="col-6 col-lg-4">
                    <img
                      class="card-img-top icon"
                      src="@/assets/img/pen/ceo-sleepout-logo.png"
                      alt="Supporting Vinnies CEO Sleepout"
                    />
                    <h3>Vinnies CEO Sleepout</h3>
                    <p>
                      Vinnies provides an extensive range of services to support
                      people experiencing homelessness and extreme poverty.
                      There is no one-size-fits-all approach, so each region and
                      service tailors its support to best suit the needs of the
                      location and the individuals assisted. Each year, the
                      millions of dollars raised through Vinnies CEO Sleepout
                      help Vinnies to provide crisis accommodation, food,
                      healthcare, counselling, education and employment.
                    </p>
                  </div>
                  <div class="col-6 col-lg-4">
                    <img
                      class="card-img-top icon"
                      src="@/assets/img/pen/3rdSpace-logo.png"
                      alt="Supporting 3rd Space Brisbane, a day-time drop-in centre for
                      people at risk of homelessness."
                    />
                    <h3>3rd Space</h3>
                    <p>
                      3rd Space Brisbane is a day-time drop-in centre where
                      people experiencing or at risk of homelessness find
                      support, friendship and dignity. 3rd Space helps thousands
                      of people every year through the dignity of a hot shower,
                      café style meals as well as access to support workers,
                      medical, mental health, law, tenancy, employment,
                      financial support and other services. These services focus
                      on breaking the cycle of homelessness.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </main>
    <FooterSection />
  </div>
</template>
